<template>
  <div
    class="opcaoListaBox"
    :style="
      clickedBox == nomeBox
        ? 'box-shadow: #209cee 0px 3px 8px;background-color:#209cee;color:white'
        : 'box-shadow: gray 0px 1px 4px;background-color:white;color:#209cee'
    "
  >
    <div
      class="headerOpcao"
      :style="
        clickedBox == nomeBox
          ? 'border-bottom: 1px solid white;'
          : 'border-bottom: 1px solid #209cee;'
      "
      @click="selecaoBox(nomeBox)"
    >
      <span class="textOpcao">{{ nomeBox }}</span>
      <div>

        <span
        class="material-symbols-outlined iconPessoa"
        v-if="tipoPessoaEscolhida == 'Física' || tipoPessoaEscolhida == 'Indiferente'" 
        >face</span
      >
      <span
        class="material-symbols-outlined iconPessoa"
        v-if="tipoPessoaEscolhida == 'Jurídica' || tipoPessoaEscolhida == 'Indiferente'" 
        >business</span
        >
      </div>
    </div>
    <span class="textDescr">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: "OpcaoBox",

  components: {
  },

  props: {
    nomeBox: String,
    clickedBox: String,
    clicked: String,
    description: String,
    tipoPessoaEscolhida: String,
  },

  data() {
    return {
      openAtributos: false,

    };
  },
  methods: {
    selecaoBox(b) {
      this.$emit("recebeBox", b);
  
    },

  },
  computed: {
  },

  created() {
  },

};
</script>

<style lang="scss" scoped>
.opcaoListaBox {
  width: 90%;
  height: 100px;
  background-color: #209cee;
  padding: 0.5rem;
  margin: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
}
.headerOpcao {
  width: 100%;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.textOpcao {
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 600;
  font-size: 1.2rem;
  word-break: break-all;
}

.iconPessoa {
  margin: 0 2px;
}
</style>
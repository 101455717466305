import { render, staticRenderFns } from "./UploadDadosBox.vue?vue&type=template&id=062c3d86&scoped=true&"
import script from "./UploadDadosBox.vue?vue&type=script&lang=js&"
export * from "./UploadDadosBox.vue?vue&type=script&lang=js&"
import style0 from "./UploadDadosBox.vue?vue&type=style&index=0&id=062c3d86&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062c3d86",
  null
  
)

/* custom blocks */
import block0 from "./UploadDadosBox.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Fmaxcdn.bootstrapcdn.com%2Fbootstrap%2F3.3.7%2Fcss%2Fbootstrap.min.css"
if (typeof block0 === 'function') block0(component)

export default component.exports
<template>
  <tbody class="tableLines">
    <tr class="rowResults">
      <td class="text-left rowResultIdent" ref="identificador">
        <button :id="'id' + index + clickedBox" class="btnId" @click="copy(atributo_identificador)">
          <span class="text-left">
            {{ atributo_identificador }}
          </span>
        </button>

      </td>
      <td class="text-left rowResultDesc">
        {{ atributo.Descricao }}
      </td>
      <td class="text-left rowResultTipo">
        {{ atributo.Tipo }}
      </td>
    </tr>
    <div>
      <span class="tooltip__content" :class="{ show: flag }" v-if="flag">Copiado!</span>
    </div>
  </tbody>
</template>

<script>
export default {
  name: "ListaAtributos",
  data() {
    return {
      atributoIdentificador: "",
      flag: false,

    };
  },
  props: {
    listaAtributos: Array,
    listaAtributos_display: Array,
    atributo: Object,
    index: Number,
    clickedBox: String,
  },
  async mounted() { },

  methods: {
    copy(textToCopy) {
      this.flag = true;
      let removeBreakLine = textToCopy.replaceAll("\n", "");
      navigator.clipboard.writeText(removeBreakLine);
      setTimeout(() => {
        this.flag = false;
      }, 1000);
    },
    createSubstrings(originalString, maxCharsPerLine) {
      const substrings = [];
      let startIndex = 0;
      while (startIndex < originalString.length) {
        let endIndex = startIndex + maxCharsPerLine;
        if (
          endIndex < originalString.length &&
          originalString[endIndex] !== "_"
        ) {
          const underscoreIndex = this.searchUnderline(
            originalString,
            endIndex
          );
          if (underscoreIndex !== -1) {
            endIndex = underscoreIndex;
          }
        }
        if (startIndex == endIndex) break;
        substrings.push(originalString.substring(startIndex, endIndex));
        startIndex = endIndex;
      }
      return substrings;
    },
    searchUnderline(text, index) {
      for (let i = index; i >= 0; i--) {
        if (text[i] === "_") {
          return i;
        }
      }
      for (let i = index; i < text.length; i++) {
        if (text[i] === "_") {
          return i;
        }
      }
      return -1;
    },
  },

  computed: {
    atributo_identificador() {
      const text = this.atributo.Identificador;
      const textSize = 22;
      const minimoCharPorPalavra = 13;
      this.$nextTick(() => {
        let maxChars = Math.max(
          Math.floor(this.$refs.identificador.clientWidth / textSize),
          minimoCharPorPalavra
        );
        let substrings;
        if (text.length > maxChars) {
          substrings = this.createSubstrings(text, maxChars);
          this.atributoIdentificador = substrings.join("\n");
        } else {
          this.atributoIdentificador = text;
        }
      });
      return this.atributoIdentificador;
    }
  },
};
</script>

<style lang="scss" scoped>
.rowResultIdent {
  width: 150px;
  max-width: 150px;
  white-space: pre;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  
}

.rowResultDesc {
  border: none;
  min-width: 150px;
}

.rowResultTipo {
  border: none;
  width: 10%;
}


.btnId {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 2px;
  border-radius: 5px !important;
}

.btnId:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20%;
}

.btnId:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}

.tooltip__content {
  background: #616161;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  position: fixed;
  left: 18rem;
  bottom: 3%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  z-index: 99;

  @include mediaQueryMax(medium) {
    left: 0.9rem;
  }
}

.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
</style>
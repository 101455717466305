<template>
  <div class="boxInput">
    <md-field
      :class="
        this.selectedOption != null && liberaFormato
          ? 'inputFieldAlternative'
          : 'inputField'
      "
    >
      <md-select
        name="selectedOp"
        class="selectedOp"
        @input="checkIfSelected"
        v-model="selectedOption"
        :fileColuns="fileColuns"
        :id="layout"
        :style="!checkedSelects || liberaSelect ? 'cursor: not-allowed' : ''"
        :disabled="!checkedSelects || liberaSelect"
        :class="{ auto: selectedOption != '' && liberaSelect }"
        :placeholder="
          selectedOption != ''
            ? 'Coluna identificada: ' + selectedOption
            : layout
        "
        :value="selectedOption"
      >
        <div class="textSearch">
          <md-input
            v-model="searchValue"
            class="textSearchInput"
            placeholder="Procure uma coluna"
          >
          </md-input>
        </div>
        <md-option v-for="item in optionsList" :key="item" :value="item">
          {{ item }}
        </md-option>
      </md-select>
    </md-field>
    <MapFormatos
      class="dataFormat"
      ref="dataFormatRef"
      @selecionaFormatoData="selecionaFormatoData"
      @liberaFormatoCheck="liberaFormatoCheck"
      @resetFormat="resetFormat"
      :layout="layout"
      :selectedOption="selectedOption"
      :formatoData="formatoData"
      :checkedSelects="checkedSelects"
      :index="index"
      v-show="this.selectedOption != null && liberaFormato"
    />
    <div class="inputCheckbox">
      <b-form-checkbox
        v-if="!isRequired"
        type="checkbox"
        class="checkConfirm"
        @change="handleCheck()"
        v-model="checkedSelects"
        :id="'checkboxSelect' + index"
        :disabled="liberaSelect"
        switch
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import MapFormatos from "@/components/Inputs/mapFormatos";

export default {
  name: "DinamicCheckbox",
  data() {
    return {
      checkedSelects: true,
      selectedOption: "",
      check: {},
      searchValue: "",
      liberaFormato: false,
      liberaSelect: false,
      selectedFormat: "",
    };
  },
  components: {
    MapFormatos,
  },
  props: {
    layout: String,
    index: Number,
    isRequired: Boolean,
    fileColuns: Array,
    fileColunsControl: Array,
    formatoData: Object,
  },

  methods: {
    handleCheck() {
      this.setCheckboxVar(this.layout, this.checkedSelects);
      if (this.checkedSelects == false) {
        if (this.liberaFormato) {
          this.$emit("resetFormat", this.layout);

          // this.$refs.dataFormatRef.resetFormat();
        }

        for (let item in this.check) {
          this.fileColunsControl.push(item);
          delete this.check[item];
          this.fileColunsControl = this.fileColunsControl.sort();
        }

        this.selectedOption = "";
        this.$emit("resetFormat", this.layout);
        this.$emit("checkIfNull", this.layout, this.checkedSelects);
      } else {
        this.liberaSelect = false;
        this.$emit("checkIfNull", this.layout, this.checkedSelects);
      }
      this.$emit("liberaBtnEnviar");
    },

    resetFormat() {
      this.$emit("resetFormat", this.layout);
    },

    checkIfSelected() {
      if (this.selectedOption != "") {
        this.$emit(
          "recebeOpcao",
          this.layout,
          this.selectedOption,
          this.checkedSelects
        );

        this.check[this.selectedOption] = true;
        this.setMapeamento(this.selectedOption, this.index);

        for (let item in this.check) {
          if (item != this.selectedOption) {
            this.fileColunsControl.push(item);
            delete this.check[item];
            this.fileColunsControl = this.fileColunsControl.sort();
          }
        }
        for (var i = 0; i < this.fileColunsControl.length; i++) {
          if (this.fileColunsControl[i] == this.selectedOption) {
            this.fileColunsControl.splice(i, 1);
            i--;
          }
        }
      }

      this.$emit("liberaBtnEnviar");
    },

    selecionaFormatoData(f) {
      this.selectedFormat = f;
      this.$emit("selecionaFormatoData", this.layout, this.selectedFormat);
    },

    liberaFormatoCheck() {
      this.liberaFormato = true;
    },
    ...mapActions(useBacktestMemoryStore, ["setMapeamento", "setCheckboxVar"]),
  },

  computed: {
    optionsList() {
      if (this.searchValue.trim().length > 0) {
        return this.fileColunsControl.filter((column) =>
          column.toLowerCase().includes(this.searchValue.trim())
        );
      }
      return this.fileColunsControl;
    },
    ...mapState(useBacktestMemoryStore, ["mapeamentoMemoryObj", "checkboxVar"]),
  },

  created() {
    if (this.checkboxVar[this.layout] != undefined) {
      this.checkedSelects = this.checkboxVar[this.layout];
      this.handleCheck();
    }
    if (
      this.mapeamentoMemoryObj != {} &&
      Object.keys(this.mapeamentoMemoryObj).length > 0 &&
      this.mapeamentoMemoryObj.hasOwnProperty(this.index)
    ) {
      this.selectedOption = this.mapeamentoMemoryObj[this.index];
      this.checkIfSelected();
    }
    if (this.layout == "identificador_etiqueta_primaria") {
      this.checkedSelects = false;
      this.handleCheck();
    }
    if (this.layout == "identificadores_etiquetas_secundarias") {
      this.checkedSelects = false;
      this.handleCheck();
    }

    for (let item in this.fileColuns) {
      if (this.fileColuns[item].trim() == this.layout) {
        this.selectedOption = this.fileColuns[item];
        this.checkIfSelected();
        this.liberaSelect = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.boxInput {
  @include flex-row();
  width: 100%;
  height: 100%;
  align-content: center;
  gap: 10px;
}

.inputField {
  display: flex;
  align-items: center;
  width: 85%;
  height: 2.8rem !important;
  padding: 0 0 2px 0 !important;
}

.inputFieldAlternative {
  display: flex;
  align-items: center;
  width: 40%;
  margin-right: 2%;
  color: black;
  height: 2.8rem !important;
  padding: 0 0 1px 0 !important;
}
.inputCheckbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 15%;
}
.selectedOp {
  cursor: pointer;
}
.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}
.dataFormat {
  display: flex;
  justify-content: center;
}
</style>

<style>
.md-menu.md-select.auto .md-input {
  font-weight: 500;
  background-color: #80ff003a;
  border-radius: 20px;
  padding: 0 5px;
  height: 20px;
}
</style>

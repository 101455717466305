<template>
  <div class="boxDetalhes">
    <div class="header">
      <span class="tituloDetalhes">{{ clickedBox }}</span>
    </div>
    <br />
    <div>
      <table id="tableComponent" class="table">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field" class="text-left">
              {{ field }}
            </th>
          </tr>
        </thead>
        <ListaAtributos
          v-for="(atributo, index) in listaAtributos_display"
          :key="index"
          :listaAtributos="listaAtributos"
          :listaAtributos_display="listaAtributos_display"
          :atributo="atributo"
          :index="index"
          :clickedBox="clickedBox"
        >
        </ListaAtributos>
      </table>
    </div>
  </div>
</template>

<script>
import ListaAtributos from "@/components/Inputs/ListaAtributos.vue";

export default {
  name: "DetalhesRotas",

  components: {
    ListaAtributos,
  },

  props: {
    layoutBox: Object,
    clickedBox: String,
    listaAtributos: Array,
    listaAtributos_display: Array,
    liberaSpinnerCard: Boolean,
  },
  data() {
    return {
      fields: ["Identificador", "Descrição", "Tipo"],
    };
  },
  methods: {
  },

  mounted() {
  },

};
</script>

<style lang="scss" scoped>
.boxDetalhes {
  height: 100%;
  width: 100%;
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.table {
  cursor: default;
}
.header {
  margin: 0 !important;
  width: 100%;
  border-bottom: 1px solid #209cee;
  display: flex;
  justify-content: space-between;
}
.tituloDetalhes {
  color: #209cee;
  padding: 0 !important;
  font-weight: 600;
  font-size: 1.2rem;
}

</style>
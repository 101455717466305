<template>
  <div class="full container-fluid text-wrap">
    <md-card class="messageField">
      <div class="table-box">
        <table style="min-width: 100%">
          <thead>
            <tr class="linhas-tabela">
              <th
                v-for="(header, index) in headers"
                :key="index"
                class="itens-tabela"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in firstFiveRows"
              :key="rowIndex"
              :class="{ impar: rowIndex % 2 != 0 }"
              class="linhas-tabela"
            >
              <td
                v-for="(value, column) in row"
                :key="column"
                class="itens-tabela"
              >
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </md-card>
  </div>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  name: "EstruturaArquivo",

  props: {
    file: File,
    separadorDefinido: String,
  },

  components: {},

  data() {
    return {
      headers: [],
      firstFiveRows: [],
    };
  },

  methods: {
    readFile() {
      var reader = new FileReader();

      reader.readAsText(this.file, "UTF-8");

      reader.onload = () => {
        var text = reader.result;
        const linhas = text.split("\n").slice(0, 6);

        const headerLine = linhas[0];
        this.headers = headerLine.split(this.separadorDefinido);

        this.firstFiveRows = linhas.slice(1).map((line) => {
          const columns = line.split(this.separadorDefinido);
          const rowObject = {};
          this.headers.forEach((headerItem, index) => {
            rowObject[headerItem] = columns[index];
          });
          return rowObject;
        });
      };
    },
  },

  created() {
    this.readFile();
  },
};
</script>

<style lang="scss" scoped>
.full {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.messageField {
  max-height: 300px;
  width: 100%;
  margin: 10px;
}

.table-box {
  display: flex;
  overflow: scroll;
  max-height: 500px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
}

.impar {
  background-color: #dfdfdf80;
}

.linhas-tabela {
  display: flex;
  width: 100%;
  gap: 5px;
  min-height: 40px;
}

.itens-tabela {
  flex: 1;
  text-align: center;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
}
</style>

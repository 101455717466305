<template>
  <md-card class="fieldExt container-fluid align-top">
    <md-card class="fieldInt">
      <TituloBox
        :label="'Resumo das informações do Lote: '"
        :iconLabel="'stacked_bar_chart'"
      />
      <div class="boxResume">
        <div class="reviewFields">
          <div class="left">
            <md-card class="cardInfo">
              <h5>Informações do projeto:</h5>
              <strong>Nome do projeto:</strong>
              <md-card class="infoLote">{{ projectName }}</md-card>
              <strong>Nome do arquivo:</strong>
              <md-card class="infoLote fileNameInfoLote">{{
                file.name
              }}</md-card>
              <strong>Tipo de consulta:</strong>
              <md-card class="infoLote fileNameInfoLote">
                {{ rotaConsulta }} | {{ abordagemConsulta }}</md-card
              >
              <div class="buttonCardInfo">
                <div class="boxInfo">
                  <router-link to="/backtest/nova/3" class="router">
                    <div class="editButton">
                      <span
                        class="material-symbols-outlined iconMap2"
                        style="margin-bottom: 3px"
                        >edit_square</span
                      >
                      <span>EDITAR ARQUIVO</span>
                    </div>
                    <md-tooltip md-direction="bottom">
                      Voltar para upload do Lote
                    </md-tooltip>
                  </router-link>
                </div>
              </div>
            </md-card>
            <md-card class="cardInfo">
              <h5>Informações de mapeamento:</h5>
              <div>
                <strong>Cabaçalho mapeado:</strong>
                <md-card class="infoLote">{{ finalHeader }}</md-card>
              </div>
              <div>
                <strong>Separador definido:</strong>
                <md-card class="infoLote">{{ separadorDefinido }}</md-card>
              </div>
              <div v-if="finalFormat">
                <strong>Formatos definidos:</strong>
                <md-card class="infoLote">{{ finalFormat }}</md-card>
              </div>
              <div>
                <strong>Cabeçalho do arquivo:</strong>
                <md-card class="infoLote">{{ fileColuns }}</md-card>
              </div>
              <div class="buttonCardInfo">
                <div class="boxInfo">
                  <router-link to="/backtest/nova/5" class="router editMap">
                    <div class="editButton">
                      <span class="material-symbols-outlined iconMap2"
                        >edit</span
                      >
                      <span>EDITAR MAPEAMENTO</span>
                    </div>
                    <md-tooltip md-direction="bottom">
                      Voltar para mapeamento do Lote
                    </md-tooltip>
                  </router-link>
                </div>
              </div>
            </md-card>
          </div>
          <div class="right">
            <div>
              <Validacoes
                @resultadoValid="resultadoValid"
                :file="file"
                :projectName="projectName"
                :opcaoPessoaSelecionada="opcaoPessoaSelecionada"
                :fileColuns="fileColuns"
                :separadorDefinido="separadorDefinido"
                :finalHeader="finalHeader"
                :finalFormat="finalFormat"
              />
            </div>
            <div
              class="alertaPermissao"
              v-if="this.limiteExecucaoUtilizado > 1"
            >
              <div class="iconAlert" style="color: #b71c1c">
                <span class="material-symbols-outlined icon"> error </span>
              </div>
              <div
                class="textAlert"
                v-if="this.usuario.PercentualConsultasUtilizadas != 100"
              >
                A execução deste Lote excede o seu
                <strong>limite máximo de consultas</strong> permitido. <br />
                Por favor <a href="/suporte/contato">clique aqui</a> para enviar
                uma mensagem ao suporte e requisitar um ajuste em seu
                <strong>limite de consultas</strong>.
              </div>
              <div
                class="textAlert"
                v-if="this.usuario.PercentualConsultasUtilizadas == 100"
              >
                Você alcançou o
                <strong>limite máximo de consultas</strong> permitido. <br />
                Por favor <a href="/suporte/contato">clique aqui</a> para enviar
                uma mensagem ao suporte e requisitar um ajuste em seu
                <strong>limite de consultas</strong>.
              </div>
            </div>
            <div
              class="alertaPermissao"
              v-if="
                this.limiteExecucaoUtilizado < 1 &&
                this.limiteExecucaoUtilizado > 0.8
              "
            >
              <div class="iconAlert">
                <span class="material-symbols-outlined icon"> error </span>
              </div>
              <div
                class="textAlert"
                v-if="
                  this.limiteExecucaoUtilizado >= 0.8 &&
                  this.usuario.PercentualConsultasUtilizadas < 80
                "
              >
                A execução deste Lote irá exceder
                <strong>80% do limite máximo de consultas</strong> permitido.
                <br />
                Por favor <a href="/suporte/contato">clique aqui</a> para enviar
                uma mensagem ao suporte e requisitar um ajuste em seu
                <strong>limite de consultas</strong>.
              </div>
              <div
                class="textAlert"
                v-if="this.usuario.PercentualConsultasUtilizadas > 80"
              >
                Você alcançou o
                <strong>80% do limite máximo de consultas</strong> permitido.
                <br />
                Por favor <a href="/suporte/contato">clique aqui</a> para enviar
                uma mensagem ao suporte e requisitar um ajuste em seu
                <strong>limite de consultas</strong>.
              </div>
            </div>
          </div>
        </div>
        <div class="buttonCardInfo" style="margin-bottom: 0.5rem !important">
          <button class="button-backstest" @click="prevStep">ANTERIOR</button>

          <div class="dropButton">
            <div class="mainButton">
              <b-button
                class="selectButton"
                @click="sendFile"
                :disabled="
                  !resultadoValidacao ||
                  resultadoValidacao.data.cpf_cnpj.validos == 0 ||
                  this.limiteExecucaoUtilizado > 1
                "
                >CRIAR E INICIAR LOTE</b-button
              >
              <b-button class="selectArrow" @click="visible = !visible">
                <span class="material-symbols-outlined iconMap1"
                  >expand_more</span
                >
              </b-button>
            </div>
            <b-button-group vertical v-if="visible" class="selectItemBox">
              <b-button
                class="selectItem"
                @click="createFile"
                :disabled="
                  !resultadoValidacao ||
                  resultadoValidacao.data.cpf_cnpj.validos == 0
                "
                >CRIAR LOTE</b-button
              >
              <b-button class="selectItem" @click="cancelFile"
                >CANCELAR LOTE</b-button
              >
            </b-button-group>
          </div>
        </div>
      </div>
      <div v-if="libera">
        <Loading class="spinner" />
      </div>
    </md-card>
  </md-card>
</template>

<script>
import "aos/dist/aos.css";
import { consultasService } from "@/services";
import { historicoService } from "@/services";
import { mapState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import Loading from "@/components/Loading";
import Swal from "sweetalert2";
import Validacoes from "@/components/Inputs/Validacoes.vue";
import TituloBox from "@/components/Visuais/TituloBox.vue";

export default {
  name: "UploadDadosResume",

  components: {
    Loading,
    Validacoes,
    TituloBox,
  },

  props: {
    file: File,
    opcaoPessoaSelecionada: String,
    fileColuns: Array,
    separadorDefinido: String,
    finalHeader: String,
    finalFormat: String,
    projectName: String,
    usuario: Object,
  },

  data() {
    return {
      resultadoLote: null,
      errorAlert: "",
      visible: false,
      libera: false,
      resultadoValidacao: null,
      linhasAExecutar: 0,
      linhasTotaisArquivo: 0,
      limiteExecucaoDisponivel: 0,
      limiteExecucaoUtilizado: 0,
      lotesIniciados: null,
    };
  },

  methods: {
    async sendFile() {
      this.resetFile();
      this.$emit("showErrorStatus", this.errorAlert);
      this.$emit("resultadoStatus", this.resultadoLote);
      this.libera = true;

      const rotaProduto =
        this.rotaConsulta == "Box de Modelos" ? "v3/modelo" : "v3/box";

      var objetoConsulta = {};
      objetoConsulta["arquivo"] = this.file;
      objetoConsulta["tipo_pessoa"] = this.opcaoPessoaSelecionada;
      objetoConsulta["cod_ref"] = this.abordagemConsulta;
      objetoConsulta["produto"] = rotaProduto;
      objetoConsulta["caracter_separador"] = this.separadorDefinido;
      objetoConsulta["mapeamento_cabecalho"] = this.finalHeader;
      objetoConsulta["mapeamento_formatos"] = this.finalFormat;
      objetoConsulta["ignorar_linhas_duplicadas"] =
        this.ignoraLinhaDuplicadaMemory;
      objetoConsulta["nome_projeto"] = this.projectName;

      try {
        this.resultadoLote = await consultasService.submeteLote(objetoConsulta);
        this.showAlert(true);
        this.startLote(this.resultadoLote.data.identificacao_execucao);
        this.$emit("resultadoStatus", this.resultadoLote);

        // this.$emit('resetResume')
      } catch (error) {
        this.showAlert(false);
        this.errorAlert = error.toString();
        this.$emit("showErrorStatus", this.errorAlert);
        // this.$emit('resetResume')
      }
    },

    async startLote(identific) {
      var objetoConsulta = {};
      objetoConsulta["identificacaoExecucao"] = identific;

      try {
        this.execucaoLote = await consultasService.iniciaLote(objetoConsulta);
      } catch (error) {
        this.errorAlertExec = error.toString();
      }
    },

    async createFile() {
      this.resetFile();
      this.$emit("showErrorStatus", this.errorAlert);
      this.$emit("resultadoStatus", this.resultadoLote);
      this.libera = true;

      const rotaProduto =
        this.rotaConsulta == "Box de Modelos" ? "v3/modelo" : "v3/box";

      var objetoConsulta = {};
      objetoConsulta["arquivo"] = this.file;
      objetoConsulta["produto"] = rotaProduto;
      objetoConsulta["tipo_pessoa"] = this.opcaoPessoaSelecionada;
      objetoConsulta["cod_ref"] = this.abordagemConsulta;
      objetoConsulta["caracter_separador"] = this.separadorDefinido;
      objetoConsulta["mapeamento_cabecalho"] = this.finalHeader;
      objetoConsulta["mapeamento_formatos"] = this.finalFormat;
      objetoConsulta["ignorar_linhas_duplicadas"] =
        this.ignoraLinhaDuplicadaMemory;
      objetoConsulta["nome_projeto"] = this.projectName;

      try {
        this.resultadoLote = await consultasService.submeteLote(objetoConsulta);
        this.showAlert(true);
        this.$emit("resultadoStatus", this.resultadoLote);
      } catch (error) {
        this.showAlert(false);
        this.errorAlert = error.toString();
        this.$emit("showErrorStatus", this.errorAlert);
      }
    },

    cancelFile() {
      this.$emit("resetResume");
      this.backToStart();
    },

    backToStart() {
      this.$router.push("/backtest/nova/0");
    },

    showAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Sucesso!!",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.libera = false;
          },
          didClose: () => {
            if (this.resultadoLote) {
              this.$router.push("/backtest/nova/7");
            }
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.libera = false;
          },
          didClose: () => {
            if (this.errorAlert) {
              this.$router.push("/backtest/nova/7");
            }
          },
        });
      }
    },

    async recuperaLoteInfo() {
      const objetoHistorico = {
        periodo_de_brt: this.$moment()
          .subtract(20, "days")
          .format("YYYY-MM-DD"),
        periodo_ate_brt: this.$moment().format("YYYY-MM-DD"),
        status: "Iniciado",
      };
      const query = new URLSearchParams(objetoHistorico);

      try {
        this.lotesIniciados =
          await historicoService.recuperaDadosHistoricoLotes(query.toString());
        for (let j in this.lotesIniciados) {
          if (this.lotesIniciados[j].nome_usuario == this.usuario.Usuario) {
            this.linhasAExecutar +=
              this.lotesIniciados[j].qtde_linhas_total -
              this.lotesIniciados[j].qtde_linhas_processadas;
          }
        }
      } catch (error) {}
    },

    calculaLimiteExecucao() {
      this.limiteExecucaoDisponivel =
        this.usuario.LimiteDeConsultas -
        this.usuario.ConsultasAtual -
        this.linhasAExecutar -
        this.linhasTotaisArquivo;

      this.limiteExecucaoUtilizado =
        1 - this.limiteExecucaoDisponivel / this.usuario.LimiteDeConsultas;
    },

    resetFile() {
      this.resultadoLote = null;
      this.errorAlert = "";
    },

    resultadoValid(r) {
      this.resultadoValidacao = r;
      Object.values(this.resultadoValidacao.data).forEach((item) => {
        let invalidos = item.invalidos;
        let validos = item.validos;
        this.linhasTotaisArquivo = invalidos + validos;
      });
      this.calculaLimiteExecucao();
    },

    prevStep() {
      this.$router.push("/backtest/nova/5");
    },
  },

  created() {
    this.recuperaLoteInfo();
  },

  computed: {
    ...mapState(useBacktestMemoryStore, [
      "layoutMemory",
      "rotaConsulta",
      "abordagemConsulta",
      "ignoraLinhaDuplicadaMemory",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.fieldExt {
  background-color: #f9f7ff;
  border-radius: 5px;
  display: flex;
  min-height: 260px;
  max-width: 90% !important;
  padding: 10px;
  @include mediaQueryMax(small) {
    margin: 15px 0px 0 0 !important;
    min-height: 260px;
    min-width: 95% !important;
  }
}
.fieldInt {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0px !important;
  padding: 0 15px 0 15px !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  @include mediaQueryMax(small) {
    min-height: 100%;
  }
}

.boxResume {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .reviewFields {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @include mediaQueryMax(small) {
      flex-direction: column;
    }
    .left {
      height: 100%;
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include mediaQueryMax(small) {
        justify-content: center;
        width: 100%;
      }
      .cardInfo {
        margin: 10px 10px 0px 10px;
        padding: 5px;
        width: 100%;
        @include mediaQueryMax(large) {
          width: 97%;
        }
        min-height: 4rem;
        .infoLote {
          margin: 5px 5px 8px 5px;
          padding: 5px 10px 5px 10px;
          background-color: #ddedf7;
          width: fit-content;
          word-break: break-all;
          @include mediaQueryMax(small) {
            width: 95%;
          }
        }
        .fileNameInfoLote {
          @include mediaQueryMax(small) {
            margin: 5px 5px 8px 5px;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      min-height: 560px;

      @include mediaQueryMax(small) {
        width: 100%;
      }

      .alertaPermissao {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: end;
        width: 90%;
        padding: 1%;
        border: 0.1px dashed #c4c4c4;
        color: #777777;
      }

      .textAlert {
        margin-left: 3%;
      }
      .iconAlert {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(255, 196, 0);
        width: 36px;
        margin: 5px;
      }
      .icon {
        font-size: 36px;
      }
    }
  }
  .buttonCardInfo {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10%;
    padding: 5px 10px 0px 0px;

    @include mediaQueryMax(small) {
      width: 96%;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px 0px 0px 0px;
    }
    .boxInfo {
      width: 10rem;
      height: 2.5rem;
      margin-right: 0.5rem;
      margin: 0 0.2rem 0 0;
      @include mediaQueryMax(small) {
        width: 100%;
        margin: 0rem 0 0.5rem 0;
      }
      .editMap {
        @include mediaQueryMax(small) {
          margin: 0px 0 0px 0 !important;
        }
      }
      .router {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        @include mediaQueryMax(small) {
          margin: 40px 0 0px 0;
        }

        .editButton {
          @include flex-row();
          background-color: #209cee;
          width: 100%;
          border-radius: 6px 6px 6px 6px;
          border-color: #209cee;
          font-size: 10px;
          font-weight: 500;
          font-family: inherit;
          align-items: center;
          justify-content: center;
          color: white;
          margin: 0 0 5px 0;
          @include mediaQueryMax(small) {
            margin: 0 0 30px 0;
            height: 35px;
          }

          .iconMap2 {
            color: white;
            font-weight: 300;
            font-size: large;
            margin-right: 2px;
          }
        }
        .editButton:hover {
          background-color: #2b8ccd;
        }
        .editButton:active {
          background-color: #2b8ccd;
        }
      }
    }
    .dropButton {
      width: 12rem;
      // height: 10px !important;
      margin: 9px;

      @include mediaQueryMax(small) {
        width: 100%;
        margin: 10px 0;
      }
      .mainButton {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 35px !important;

        .selectButton {
          background-color: #209cee;
          width: 90%;
          border-radius: 3px 0px 0px 3px;
          border-color: #209cee;
          font-size: 13px;
          font-weight: 600;
          font-family: inherit;
          padding: 0;
        }
        .selectButton:hover {
          background-color: #2b8ccd;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        }
        .selectArrow {
          background-color: #3596d7;
          width: 10%;
          border-radius: 0px 6px 6px 0px;
          border-color: #209cee;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .selectArrow:hover {
          background-color: #2c7db3;
        }
      }
      .selectItemBox {
        width: 100%;
        .selectItem {
          border: 1px solid white;
          font-size: 10px;
          font-weight: 500;
          background-color: #56abe3;
          font-weight: 500;
        }
        .selectItem:hover {
          background-color: #2b8ccd;
        }
      }
    }
  }
}

.prevBtn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.content h5 {
  margin-bottom: 5px;
}
.iconMap1 {
  vertical-align: middle;
  height: 1.5rem;
  color: white;
}
.spinner {
  // width: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
}
</style>

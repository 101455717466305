<template>
  <md-card class="boxBacktest container-fluid align-top" v-show="file">
    <div class="boxSucess" v-if="resultadoLote && !errorAlert">
      <TituloBox
        :label="'Status de carregamento de arquivo: '"
        :iconLabel="'insights'"
      />
      <div class="infoLote">
        <div class="infoLoteLeft">
          <h5 style="margin: 0.5rem 0 0 0">Informações do Lote:</h5>
          <div class="respInfo" id="infoResult">
            <p class="infoLine">
              <button
                class="copy"
                v-clipboard:copy="resultadoLote.data.identificacao_execucao"
              >
                <span class="material-symbols-outlined copybtn"
                  >content_copy</span
                >
                <md-tooltip md-direction="top">
                  Copiar <strong>Identificação</strong></md-tooltip
                >
              </button>
              <strong min-width="90px" class="infoSize">Identificação: </strong>
              {{ " " + resultadoLote.data.identificacao_execucao }}
            </p>
            <p class="infoLine">
              <button
                class="copy"
                v-clipboard:copy="resultadoLote.data.nome_projeto"
              >
                <span class="material-symbols-outlined copybtn"
                  >content_copy</span
                >
                <md-tooltip md-direction="top">
                  Copiar <strong>Nome do Projeto</strong></md-tooltip
                >
              </button>
              <strong class="infoSize">Nome do Projeto: </strong>
              {{ resultadoLote.data.nome_projeto }}
            </p>
          </div>
        </div>
        <div class="infoLoteRight">
          <span class="boxInfoInside">
            <md-card class="fieldStatus" v-if="!errorAlert && resultadoLote">
              <span class="material-symbols-outlined iconStatus statusSubmetido"
                >check_circle</span
              >

              <strong min-width="90px" class="infoSize"
                >Lote submetido com sucesso
              </strong>
            </md-card>
          </span>
        </div>
      </div>
      <div class="infoTabela">
        <h5 style="margin: 0 0 1rem 0">Características da Tabela:</h5>
        <div class="tabLote">
          <div class="linLote">
            <b>Linhas </b>
            <p class="resp col">{{ resultadoLote.data.qtde_linhas_total }}</p>
          </div>
          <div class="colLote">
            <b>Colunas </b>
            <p class="resp col">
              {{ resultadoLote.data.mapeamento_cabecalho.split(";").length }}
            </p>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <div class="boxInfoBtn">
          <div class="router">
            <button class="editButton" @click="goInteg">
              <span class="material-symbols-outlined iconMap2"
                >room_preferences</span
              >
              <span>INTEGRAÇÃO SKYNET</span>
            </button>
            <md-tooltip md-direction="bottom">
              Ir para Integração com o SKYNET
            </md-tooltip>
          </div>
        </div>
        <div class="boxInfoBtn">
          <div class="router">
            <button class="editButton" @click="goHist">
              <span class="material-symbols-outlined iconMap2">send</span>
              <span>HISTÓRICO</span>
            </button>
            <md-tooltip md-direction="bottom">
              Ir para Histórico de Execução de Lotes
            </md-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="errorAlert" class="box-erro">
      <span class="material-symbols-outlined icon-error task"
        >highlight_off</span
      >
      <p class="task"><b>Falha no carregamento do arquivo.</b></p>
      <div class="boxSucess">
        <div class="infoErro">
          <p class="resp">{{ errorAlert }}</p>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </md-card>
</template>

<script>
import "aos/dist/aos.css";
import TituloBox from "@/components/Visuais/TituloBox.vue";

export default {
  name: "UploadDadosStatus",

  props: {
    file: File,
    resultadoLote: Object,
    errorAlert: String,
  },

  components: {
    TituloBox,
  },

  data() {
    return {};
  },

  methods: {
    goHist() {
      let routeLote = this.$router.resolve({
        path: "/backtest/historico_backtest",
      });
      window.open(routeLote.href, "_blank");
    },
    goInteg() {
      let routeInteg = this.$router.resolve({
        path:
          "/integracoes_skynet/" +
          this.resultadoLote.data.identificacao_execucao +
          "/0",
      });
      window.open(routeInteg.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.boxBacktest {
  background-color: #f9f7ff;
  padding: 15px !important;
  min-height: 380px;
  width: 90% !important;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  padding: 20px;

  @include mediaQueryMax(medium) {
    min-height: 400px;
  }
  @include mediaQueryMax(small) {
    min-height: 565px;
    width: 85% !important;
  }
}
.boxSucess {
  display: flex;
  justify-content: center;
  padding: 1%;
  border: solid 1px var(--gray-lighter);
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  min-height: 85%;
  flex-flow: wrap;
  box-sizing: border-box;
  word-break: break-all;
  background-color: #fff;
  transition: transform 2s ease;
  transform-style: preserve-3d;
  width: 100%;
  @include mediaQueryMax(medium) {
    min-height: 390px;
  }
  @include mediaQueryMax(small) {
    padding: 5px 15px;
    min-height: 540px;
  }
}

.icon-error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 2rem !important;
  transition: 1s all ease;
  margin: 20px 5px 20px 5px !important;
}
.infoLote {
  width: 100%;
  min-height: 35%;
  position: relative;
  font-size: 0.9rem;
  border-bottom: 1px solid var(--accent-50);
  vertical-align: center;
  margin: 0 0 1rem 0;
  display: flex;
  @include mediaQueryMax(medium) {
    flex-direction: column;
  }
}
.infoTabela {
  width: 100%;
  // min-height: 40%;
  position: relative;
  font-size: 0.9rem;
  vertical-align: center;
  // margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;

  @include mediaQueryMax(medium) {
  }
}
.infoLoteLeft {
  width: 50%;
  @include mediaQueryMax(medium) {
    width: 100%;
  }
}
.infoLoteRight {
  width: 50%;
  @include mediaQueryMax(medium) {
    width: 100%;
  }
}

.boxInfoInside {
  display: flex;
  // width: 50%;
  height: 90%;
  align-items: flex-start;
  flex-direction: column;
  @include mediaQueryMax(medium) {
    width: 100%;
  }
}

.fieldStatus {
  width: 90%;
  padding: 5px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  @include mediaQueryMax(medium) {
    margin: 10px 20px;
  }
}
.respError {
  margin: 5px;
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.iconStatus {
  font-size: 2rem !important;
}

.statusSubmetido {
  color: #1da0f2;
}

.infoSize {
  min-width: 92px;
  margin: 0 5px 0 0px;
  word-break: break-word;
  @include mediaQueryMax(medium) {
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.fieldStatus {
  width: 90%;
  padding: 5px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  @include mediaQueryMax(medium) {
    margin: 10px 20px;
  }
}
.tabLote {
  display: flex;
  width: 100%;
  min-height: 4rem;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 1rem 0;
}
.linLote {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-height: 3rem;
  position: relative;
  font-size: 0.9rem;
  border: solid 1px var(--gray-lighter);
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0 0 0.5rem;
}
.colLote {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-height: 3.5rem;
  position: relative;
  font-size: 0.9rem;
  border: solid 1px var(--gray-lighter);
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0 0 0.5rem;
}

.box-erro {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.task {
  display: inline;
  position: relative;
  vertical-align: middle;
  float: inline-start;
}
.infoErro {
  width: 95%;
  font-weight: 500;
  position: relative;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  vertical-align: middle;
  align-self: center;
  justify-content: center;
  text-align: center;
}
.resp {
  margin: 0% 0% 1% 0%;
  display: flex;
  vertical-align: middle;
  align-self: center;
  align-items: center;
  min-height: 35%;
}
.respInfo {
  margin: 20px 0 5px 0;
  vertical-align: middle;
  align-self: center;
  align-items: center;
  min-height: 50%;
}

.infoLine {
  width: 100%;
  display: flex;
}
.col {
  color: #2196f3;
  font-weight: 700;
  font-size: large;
  align-items: flex-start;
  padding: 0px 0px 0px 0px !important;
}
.copy {
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  height: 50%;
  float: right;
  margin: -1% 0% 0% 0%;
  padding: 0.5% !important;
}
.copy:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 25%;
}
.copy:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}
.copybtn {
  color: rgba(148, 148, 148, 0.7);
  transition: 1s all ease;
}

.infoSize {
  min-width: 92px;
  margin: 0 5px 0 0;
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;

  @include mediaQueryMax(small) {
    flex-direction: column;
    margin: 0;
  }
}
.boxInfoBtn {
  height: 2.5rem;
  margin: 0 10px 0 0;

  @include mediaQueryMax(small) {
    min-width: 100%;
    display: flex;
    align-items: center;
    margin: 0rem 0 0.5rem 0;
  }
  .router {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    @include mediaQueryMax(small) {
      margin: 0px;
    }

    .editButton {
      background-color: #209cee;
      color: white;
      width: 180px;
      height: 34px !important;
      border-radius: 6px 6px 6px 6px;
      border-color: transparent;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 15px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      @include mediaQueryMax(small) {
        transition: transform 2s ease;
        width: 100%;
        margin: 0 5px 5px 0;
        height: 35px;
      }

      .iconMap2 {
        color: white;
        font-weight: 300;
        font-size: large;
        margin: 0 5px 0 0;
      }
    }
    .editButton:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    .editButton:active {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    }
  }
}
</style>

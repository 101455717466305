<template>
  <div class="body" :class="{ unico: visualizaSumario }">
    <div class="sumario" v-if="!visualizaSumario">
      <div class="produtos-selecionados">
        <span class="sumario__titulo">Produtos Padrão:</span>
        <div
          class="produtos"
          v-for="produto in produtoObrigatorio"
          :key="produto.Nome"
        >
          <ul>
            <li class="item">
              <span class="material-icons icon">{{ produto.Icone }}</span
              >{{ produto.Nome }}
            </li>
          </ul>
        </div>
      </div>
      <div class="produtos-selecionados">
        <span class="sumario__titulo">
          Produto Selecionado
          <span v-if="produtos.length != 1"> s </span>
          :
        </span>

        <div class="produtos" v-for="produto in produtos" :key="produto.Box">
          <ul>
            <li class="item">
              <span class="material-icons icon">{{ produto.Icone }}</span
              >{{ produto.Nome }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <ValidationObserver
      class="corpo"
      v-slot="{ invalid }"
      ref="observerValidacao"
    >
      <div class="favoritos" v-if="exibirFavoritos">
        <md-field class="selectFav">
          <label
            for="parametrosFavoritos"
            style="display: flex; align-items: center"
          >
            <span
              class="material-symbols-outlined star"
              :class="{ vazio: imprimeParametro.length == 0 }"
            >
              grade
            </span>
            Parâmetros Favoritos
          </label>
          <md-select
            v-model="favoritoSelecionado"
            name="parametrosFavoritos"
            id="parametrosFavoritos"
            :disabled="imprimeParametro.length == 0"
          >
            <md-option :value="''" style="color: gray !important">
              --Vazio--
            </md-option>
            <span v-for="(parametros, index) in imprimePfFiltro" :key="index">
              <md-option
                :value="parametros.Descrição"
                :id="'opcao-' + index"
                style="white-space: wrap"
              >
                {{ parametros.Descrição }}
              </md-option>
              <b-tooltip
                :target="'opcao-' + index"
                triggers="hover"
                placement="bottom"
                variant="dark"
                boundary="window"
                customClass="tooltip-custom"
              >
                <span
                  class="results-text"
                  v-for="(nome, i) in Object.keys(parametros)"
                  :key="i"
                >
                  <p class="results-text" v-if="i != 0">
                    <strong style="color: white"> {{ nome }}: </strong>
                    {{ parametros[nome] }}
                  </p>
                </span>
              </b-tooltip>
            </span>
          </md-select>
        </md-field>

        <button
          native-type="submit"
          class="md-primary button buttonNovo"
          @click="irPreferencias()"
          :id="'irPref'"
        >
          <span class="material-symbols-outlined icon"> add </span>
        </button>
        <b-tooltip
          :target="'irPref'"
          triggers="hover"
          placement="right"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          Adicionar Parâmetros Favoritos (Ir Preferências)
        </b-tooltip>
      </div>
      <div>
        <div v-for="campo in camposNecessarios" :key="campo.Nome">
          <ValidationProvider
            :rules="alternaRulesDosCampos(campo)"
            v-slot="{ errors, valid, dirty }"
            :required="campo.Requerido"
            :label="campo.Nome"
          >
            <div class="campo">
              <md-field
                v-mask="campo.Mascara"
                :class="selecionaClasseInput(campo, dirty, valid, errors)"
              >
                <label
                  >{{ campo.Nome }}
                  <span v-if="campo.Nome == 'DATA_REF'"> (YYYY-MM-DD) </span>
                </label>
                <md-input
                  v-model="campo.Valor"
                  :id="'input-' + campo.Nome"
                  :required="campo.Requerido"
                  :type="
                    (ocultarDados && campo.Nome == 'CPF') ||
                    (ocultarDados && campo.Nome == 'CNPJ')
                      ? 'password'
                      : 'text'
                  "
                  :disabled="campo.Disabled"
                  @keyup.enter="decideConsulta(invalid)"
                />
              </md-field>
              <div
                class="valor-possivel"
                v-if="campo.ValorPossivel && campo.Nome != 'CEP' && ciclica"
              >
                <md-checkbox
                  v-model="campo.Selecionado"
                  @change="colocaValor(campo)"
                ></md-checkbox>
                <label class="titulo-possivel">
                  Usar {{ campo.ValorPossivel }}
                </label>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <md-card-actions class="btn-center">
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            :disabled="invalid || clicked"
            class="md-primary"
            @click="decideConsulta(invalid)"
            >Consultar</md-button
          >
        </md-card-actions>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { criaCampos } from "@/helpers";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import jsonProdutos from "@/helpers/produtos.json";
import Aos from "aos";
import "aos/dist/aos.css";

export default {
  name: "CamposConsulta",
  props: {
    campos: Array,
    produtos: Array,
    ciclica: Boolean,
    opcaoInicial: String,
  },
  data() {
    return {
      clicked: false,
      imprimeParametro: [],
      favoritoSelecionado: {},
      camposNecessarios: [],
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    imprimePfFiltro() {
      if (this.ocultarDados) {
        var dadospf = this.imprimeParametro.map((objeto) => {
          return { ...objeto };
        });

        return dadospf.map((objeto) => {
          if (objeto.CPF) {
            objeto.CPF = objeto.CPF.replace(
              /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
              "***.$2.$3-**"
            );
          }
          return objeto;
        });
      } else {
        return this.imprimeParametro;
      }
    },

    produtoObrigatorio() {
      if (this.opcaoInicial == "Pessoa Física") {
        return [
          { Nome: "Identifica Pessoa Física", Icone: "fingerprint" },
          { Nome: "Alertas Pessoa Física", Icone: "error_outline" },
        ];
      } else if (this.opcaoInicial == "Pessoa Jurídica") {
        return [
          { Nome: "Identifica Pessoa Jurídica", Icone: "business_center" },
          { Nome: "Alerta Pessoa Jurídica", Icone: "error_outline" },
        ];
      } else if (this.opcaoInicial == "Geolocalização") {
        return [
          { Nome: "Identifica Endereço", Icone: "place" },
          { Nome: "Alerta Geolocalização", Icone: "error_outline" },
        ];
      } else {
        return ``;
      }
    },

    // camposNecessarios() {
    //   var camposNecessarios = [];
    //   if (!this.campos || this.campos.length === 0) {
    //     return [];
    //   }

    //   if (this.campos.length > 0) {
    //     this.campos.forEach((campo) => {
    //       if (campo.Visivel) {
    //         if (this.opcaoInicial == "Pessoa Física" && campo.Nome != "CNPJ") {
    //           camposNecessarios.push(campo);
    //         } else if (
    //           this.opcaoInicial == "Pessoa Jurídica" &&
    //           campo.Nome != "CPF"
    //         ) {
    //           camposNecessarios.push(campo);
    //         } else if (
    //           this.opcaoInicial != "Pessoa Física" &&
    //           this.opcaoInicial != "Pessoa Jurídica"
    //         ) {
    //           camposNecessarios.push(campo);
    //         }
    //       }
    //     });
    //   }
    //   return camposNecessarios;
    // },

    visualizaSumario() {
      return this.produtos.some(
        (produto) => produto.Classificacao === "Superbox"
      );
    },

    ...mapWritableState(userPreferenceStore, {
      parametrosSugeridos: "parametrosSugeridos",
      exibirFavoritos: "exibirFavoritos",
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),
  },
  methods: {
    irPreferencias() {
      this.$router.push("/preferencias");
    },

    decideConsulta(invalid) {
      if (!invalid) {
        if (!this.ciclica) {
          this.$emit("realizaConsulta");
        } else {
          this.$emit("realizaConsultaCiclica");
        }

        this.clicked = true;
      }
    },
    colocaValor(campo) {
      if (campo.Valor != campo.ValorPossivel) {
        campo.Valor = campo.ValorPossivel;
      } else {
        campo.Valor = "";
      }
    },
    selecionaClasseInput(campo, dirty, valid, errors) {
      if (campo.Tamanho > campo.Valor.length) {
        if (dirty && campo.Valor.length != 0) {
          return "md-edit";
        }
      } else if (campo.Tamanho <= campo.Valor.length) {
        if (errors.length > 0) {
          return "md-error";
        }
      }
      if (valid && campo.Valor.length == campo.Tamanho) {
        return "md-valid";
      }
    },

    alternaRulesDosCampos(campo) {
      if (campo.Valor.length < 1 && !campo.Requerido) {
        return "";
      } else {
        return campo.Validador;
      }
    },
    tiraVisibilidadeCampos() {
      this.campos.forEach((campo) => (campo.Visivel = false));
    },

    alteraVisibilidadeCampos() {
      this.tiraVisibilidadeCampos();
      this.produtos.forEach((produto) => {
        produto.CamposParaConsulta.Obrigatorios.forEach((obr) => {
          this.campos.forEach((campo) => {
            if (campo.Nome.toLowerCase() == obr) {
              if (!campo.Visivel) {
                campo.Visivel = true;
                campo.Requerido = true;
              } else if (campo.Visivel && !campo.Requerido) {
                campo.Requerido = true;
              }
            }
          });
        });
        produto.CamposParaConsulta.Opcionais.forEach((opi) => {
          this.campos.forEach((campo) => {
            if (campo.Nome.toLowerCase() == opi && !campo.Visivel) {
              campo.Visivel = true;
            }
            if (campo.Nome == "DATA_REF") {
              if (this.usuarioLogado.Empresa != "StepWise") {
                campo.Visivel = false;
              }
              if (this.usuarioLogado.Empresa == "StepWise") {
                campo.Visivel = true;
              }
            }
          });
        });
      });
    },

    zeraCampos() {
      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Requerido = false;
        campo.Valor = "";
      });
    },

    verificaDocumento(str) {
      const cleanedStr = str.replace(/[^\d]/g, "");
      if (cleanedStr.length === 11) {
        return "CPF";
      }

      if (cleanedStr.length === 14 && str.includes("/") && str.includes("-")) {
        return "CNPJ";
      }

      if (cleanedStr.length === 8 && str.includes("-")) {
        return "CEP";
      }

      return "Descrição";
    },

    async syncAndSetParametrosSugeridos(key, targetProperty, opcao) {
      //recupera parametros sugeridos
      const preferencia = await this.syncPreferenciaGeral(
        `PortalOutbox_${key}`
      );

      if (preferencia === undefined) {
        await this.createPreferencia(`PortalOutbox_${key}`);
      } else {
        //adiciona parametros à tela
        const valorGet = preferencia.Valor;
        if (valorGet != null) {
          if (typeof this.parametrosSugeridos[targetProperty] === "undefined") {
            this.parametrosSugeridos[targetProperty] = [];
          }
          const objetos = valorGet.split("; ").map((item) => {
            const valores = item.split(", ");
            const objeto = {};
            for (let i = 0; i < valores.length; i++) {
              if (!/^ *$/.test(valores[i])) {
                objeto[this.verificaDocumento(valores[i])] = valores[i];
              }
            }
            return objeto;
          });

          const novoObjetos = objetos.filter((objeto) => {
            return Object.keys(objeto).length !== 0;
          });

          this.parametrosSugeridos[targetProperty] = novoObjetos;

          if (this.opcaoInicial === opcao) {
            this.imprimeParametro = this.parametrosSugeridos[targetProperty];
          }
        }
      }
    },

    atualizaCamposNecessarios() {
      if (!this.campos || this.campos.length === 0) {
        this.camposNecessarios = [];
      }

      if (this.campos.length > 0) {
        this.campos.forEach((campo) => {
          if (campo.Visivel) {
            if (
              !this.camposNecessarios.some((item) => item.Nome === campo.Nome)
            ) {
              if (
                this.opcaoInicial == "Pessoa Física" &&
                campo.Nome != "CNPJ"
              ) {
                this.camposNecessarios.push(campo);
              } else if (
                this.opcaoInicial == "Pessoa Jurídica" &&
                campo.Nome != "CPF"
              ) {
                this.camposNecessarios.push(campo);
              } else if (
                this.opcaoInicial != "Pessoa Física" &&
                this.opcaoInicial != "Pessoa Jurídica"
              ) {
                this.camposNecessarios.push(campo);
              }
            }
          }
        });
      }
    },

    ...mapActions(userPreferenceStore, [
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
      "syncPreferenciaExibeParametros",
      "syncPreferenciaOcultarDados",
    ]),
  },

  async mounted() {
    if (!this.ciclica) {
      this.zeraCampos();
      // this.campos = criaCampos.montaCampos(jsonProdutos);
    }
    
    this.alteraVisibilidadeCampos();
    Aos.init({ duration: 1000, once: true });
    this.atualizaCamposNecessarios();

    this.exibirFavoritos = await this.syncPreferenciaExibeParametros();
    this.ocultarDados = await this.syncPreferenciaOcultarDados();

    await this.syncAndSetParametrosSugeridos(
      "parametrosSugeridosPf",
      "pf",
      "Pessoa Física"
    );
    await this.syncAndSetParametrosSugeridos(
      "parametrosSugeridosPj",
      "pj",
      "Pessoa Jurídica"
    );
    await this.syncAndSetParametrosSugeridos(
      "parametrosSugeridosGeo",
      "geo",
      "Geolocalização"
    );

  },

  watch: {
    campos: {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.atualizaCamposNecessarios();
        }
      },
      immediate: true, // Executa o watcher logo após a criação do componente
    },

    favoritoSelecionado(newValor) {
      const parametro = this.imprimeParametro.find(
        (param) => param.Descrição === newValor
      );

      this.campos.forEach((campo) => {
        if (parametro) {
          campo.Valor = "";
          if (campo.Visivel) {
            if (parametro.hasOwnProperty(campo.Nome)) {
              campo.Valor = parametro[campo.Nome];
            }
          }
        } else {
          campo.Valor = "";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
}
ul {
  padding: 0;
}
.body {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;

  @include mediaQueryMax(small) {
    flex-direction: column-reverse;
    width: auto;
  }

  &.unico {
    justify-content: center !important;
  }
}
.sumario {
  display: flex;
  margin-bottom: 30px;
  gap: 10px;
  font-size: 0.8rem;

  @include mediaQueryMax(small) {
    flex-direction: column;
    margin-top: 30px;
  }
}
.sumario__titulo {
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin: 0 0px 0 5px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.icon {
  font-size: 1.2rem;
}
.corpo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 30vh;
  width: 80vw;
  gap: 30px;

  @include mediaQueryMax(small) {
    width: auto;
  }
}
.campo {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: left;
  align-items: center;
  margin-bottom: 20px;
}

.favoritos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 -38px 0px 0;

  .card-fav {
    background: transparent;
    flex-direction: row;
  }
  .card-header {
    width: fit-content;
    border: none;
    box-shadow: none;
    max-height: 43px;
  }
  .content-fav {
    width: 100%;
    display: flex;
  }
  .selectFav {
    margin: 0 10px 0 0;
    width: 100%;
  }
  .star {
    margin: 0 5px 0px 0;
    color: #2196f3;
    opacity: 0.4;
    font-size: 18px;
    cursor: default;

    &.vazio {
      color: gray;
    }
  }
}
.btn-center {
  display: flex;
  justify-content: center;
}

.valor-possivel {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (min-width: 600px) {
  .corpo {
    width: 30vw;
    grid-row: 1;
  }
  .body {
    padding: 0;
    gap: 3rem;
  }
  .sumario {
    margin-bottom: 0;
    flex-direction: column;
    font-size: 1rem;
    gap: 1rem;

    .produtos-selecionados {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }

    &__titulo {
      font-size: 1.2rem;
    }
    .item {
      gap: 10px;
      font-size: 1rem;
    }
    .icon {
      font-size: 1.5rem;
    }
  }
}
.buttonNovo {
  border-radius: 5px;
  background: transparent;
  color: black;
  width: 30px;
  height: 20px;
  min-height: 30px;
  padding: 1px 5px;
  font-size: 10px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .icon {
    font-size: 16px;
  }

  &:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  }

  &:active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(0.9)
  }
}
</style>

<style>
.md-list-item-text {
  white-space: normal;
}

.md-field.md-has-password .md-toggle-password {
  background-color: transparent !important;
  box-shadow: none;
}
</style>
